<template>
	<div class="homePage">
		<div class="banner">
			<el-carousel indicator-position="none" height="500px" :autoplay="true">
				<el-carousel-item v-for="(item, i) in carouselList" :key="i">
					<img class="carousel-img" :src="host + item" alt="" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 资讯 -->
		<div class="realtimeinfo">
			<div class="realtimeinfo-tit">
				<div class="top-nav">
					<div class="left-nav">
						<div class="nav-item" v-for="(item, i) in navlist" :key="i" @click="navClick(item, i)">
							<div class="iconfont" :class="navLen == i ? item.icon + ' nav-cative' : item.icon + ''"></div>
							<div :class="navLen == i ? 'nav-cative' : ''">{{ item.name }}</div>
						</div>
					</div>
					<div class="ri-btn" @click="newsPage">
						了解更多
						<i class="el-icon-right"></i>
					</div>
				</div>
				<div class="realtimeinfo-body">
					<div class="left-bdoy-item" v-for="(item, i) in newsList" :key="i" @click="newItem(item, i)">
						<div class="body-img">
							<img :src="host + item.noticeTitlePic" alt="" />
						</div>

						<div class="body-text">
							<p>{{ item.publishTime }}</p>
							<p>{{ item.noticeTitle }}</p>
							<p class="el-icon-right"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 专家风采 -->
		<div class="specialist">
			<div class="specialist-box">
				<div class="specialist-tit">
					<div class="spe-tit-left">
						<p></p>
						<p>专家风采</p>
					</div>
					<div class="spe-tit-ri">
						<div class="el-icon-arrow-left" @click="speprevBtn"></div>
						<div class="el-icon-arrow-right" @click="spenextBtn"></div>
					</div>
				</div>
				<div class="spe-carouse">
					<el-carousel
						indicator-position="none"
						height="464px"
						arrow="never"
						@change="speChange"
						:autoplay="true"
						ref="speCarousel"
						:interval="4000"
						@setActiveItem="setActiveItem"
					>
						<el-carousel-item v-for="(item, i) in specialist" :key="i">
							<div class="carouse-list">
								<div
									class="carouse-item"
									v-for="(itemk, k) in item.child"
									:key="k + 'k'"
									@click.stop="speClick(itemk, k)"
								>
									<div class="carouse-list-img">
										<img :src="host + itemk.noticeTitlePic" alt="" />
									</div>
									<div class="carouse-text" :class="spelen == k ? 'spe-active' : ''">
										<p>{{ itemk.noticeTitle }}</p>
										<p>
											{{ itemk.remark }}
											<i v-if="spelen == k" class="el-icon-right"></i>
										</p>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<!-- 学术交流 -->
		<div class="learning-box">
			<div class="learning">
				<div class="learning-item">
					<div class="learning-carousel-tit">
						<p></p>
						<p>学术交流</p>
					</div>
					<div class="learning-carousel">
						<el-carousel
							indicator-position="none"
							height="535px"
							arrow="never"
							@change="leaChange"
							ref="leaCarousel"
							@setActiveItem="leasetActiveItem"
							:autoplay="true"
							:interval="4000"
						>
							<el-carousel-item v-for="(item, i) in learning" :key="i">
								<div class="learning-carousel-item" @click="learImg(item, i)">
									<img :src="host + item.noticeTitlePic" alt="" class="carousel-item-img" />
									<p>{{ item.publishTime }}</p>
									<p>{{ item.noticeTitle }}</p>
									<p>{{ item.remark }}</p>
								</div>
							</el-carousel-item>
						</el-carousel>

						<div class="indicator-position-icon">
							<i @click="leaprevBtn" class="el-icon-arrow-left"></i>
							<i @click="leanextBtn" class="el-icon-arrow-right"></i>
						</div>
					</div>
				</div>
				<div class="learning-item">
					<div class="learning-text-tit">
						<div
							class="learning-text-tit-item"
							v-for="(item, i) in list1"
							:key="i"
							@click="education(item, i)"
							:class="educationLen == i ? 'education-active' : ''"
						>
							<p :class="'iconfont ' + item.icon"></p>
							<p>{{ item.name }}</p>
						</div>

						<div class="lear-more" @click="learmore">
							了解更多
							<i class="el-icon-right"></i>
						</div>
					</div>
					<div class="learning-text-list">
						<div class="list-item" v-for="(item, i) in educationList" :key="i" @click="learningBtn(item, i)">
							<div>
								<p>{{ item.noticeTitle }}</p>
								<p>{{ item.publishTime }}</p>
							</div>
							<div class="el-icon-right"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 关于 -->
		<div class="about-box">
			<div class="about">
				<div class="about-item">
					<p>关于</p>
					<p>{{ aboutObj.noticeTitle }}</p>
					<p class="about-html" v-html="aboutObj.noticeContent"></p>
					<div class="about-item-btn" @click="about">
						<span>了解更多</span>
						<span class="el-icon-right"></span>
					</div>
				</div>
				<div class="about-item">
					<img :src="host + aboutObj.noticeTitlePic" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	listInfoByInfoName,
	listInfoByColumnName,
	pageListInfoByColumnName,
	listInfoByColumnId,
	pageListInfoByColumnId
} from '@/api/api.js'
export default {
	data() {
		return {
			host: process.env.VUE_APP_SERVER_URL,
			carouselList: [], //轮播图
			navlist: [
				{
					name: '新闻资讯',
					id: 0,
					icon: 'icon-xinwen',
					columnId: '209'
				},
				{
					name: '会议通知',
					id: 0,
					icon: 'icon-rizhi',
					columnId: '211'
				},
				{
					name: '通知公告',
					id: 0,
					icon: 'icon-rizhi',
					columnId: '226'
				}
			],
			newstabName: '新闻动态',
			newsList: [], //新闻列表
			list1: [
				{ name: '教育培训', icon: 'icon-yewu', columnId: '213' },
				{ name: '科普推广', icon: 'icon-rizhi', columnId: '215' }
			],
			navLen: 0,
			spelen: null, //专家下标
			specialist: [], //专家列表
			learning: [], //学术交流
			speciaLen: 0,
			educationList: [], //教育培训 科普推广列表
			educationLen: 0, //教育培训 科普推广切换下标
			aboutObj: {}, //关于学会
			sepcarouselLen: 0, //专家风采轮播图下标
			leacarouselLen: 0 //学术交流轮播图下标
		}
	},
	props: {},
	components: {},
	computed: {},
	mounted() {
		this.Rotationchart()
		this.Getnews(this.navlist[0].columnId)
		this.Getspecialist()
		this.Getlearning()
		this.Geteducation(this.list1[0].columnId)
		this.Getabout()
	},
	watch: {
		// '$store.state.columnlist': {
		// 	handler(newVal) {
		// 		console.log(newVal, '===================')
		// 	}
		// }
	},
	methods: {
		leaChange(index) {
			//学术交流轮播图切换
			this.leacarouselLen = index
		},
		leaprevBtn() {
			//学术交流轮播图切换至上一张
			if (this.leacarouselLen == 1) {
				this.leacarouselLen = this.learning.length
			} else {
				this.leacarouselLen -= 1
			}
			this.leasetActiveItem()
		},
		leanextBtn() {
			//学术交流轮播图切换至下一张
			if (this.leacarouselLen > this.learning.length) {
				this.leacarouselLen = 0
			} else {
				this.leacarouselLen += 1
			}
			this.leasetActiveItem()
		},
		leasetActiveItem() {
			this.$refs.leaCarousel.setActiveItem(this.leacarouselLen)
		},
		speChange(index) {
			//专家风采轮播图切换
			this.sepcarouselLen = index
		},
		speprevBtn() {
			//专家风采轮播图切换至上一张
			if (this.sepcarouselLen == 1) {
				this.sepcarouselLen = this.specialist.length
			} else {
				this.sepcarouselLen -= 1
			}
			this.setActiveItem()
		},
		spenextBtn() {
			//专家风采轮播图切换至下一张
			if (this.sepcarouselLen > this.specialist.length) {
				this.sepcarouselLen = 0
			} else {
				this.sepcarouselLen += 1
			}
			this.setActiveItem()
		},
		setActiveItem() {
			this.$refs.speCarousel.setActiveItem(this.sepcarouselLen)
		},
		Getabout() {
			//关于学会
			let params = {
				columnId: '208'
			}
			//listInfoByColumnName(params)
			listInfoByColumnId(params)
				.then(res => {
					this.aboutObj = res[0]
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		learningBtn(item, i) {
			//点击学术交流右侧列表进入详情
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == this.list1[this.educationLen].name) {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/newdetaile',
				query: {
					id: item.noticeId
				}
			})
		},
		learImg(item, i) {
			//学术交流图片点击
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == '学术交流') {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/newdetaile',
				query: {
					id: item.noticeId
				}
			})
		},
		learmore() {
			//教育培训 科普推广右侧了解更多按钮
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == this.list1[this.educationLen].name) {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/news',
				query: {
					tabName: this.list1[this.educationLen].name
				}
			})
		},
		education(item, i) {
			//教育培训 科普推广切换
			this.educationLen = i
			this.Geteducation(item.columnId)
		},
		Geteducation(columnId) {
			//获取教育培训 科普推广
			let params = {
				columnId: columnId
			}
			//listInfoByColumnName(params)
			listInfoByColumnId(params)
				.then(res => {
					if (res.length > 3) {
						this.educationList = res.slice(0, 3)
					} else {
						this.educationList = res
					}
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		Getlearning() {
			//获取学术交流
			let params = {
				columnId: '216'
			}
			//listInfoByColumnName(params)
			listInfoByColumnId(params)
				.then(res => {
					this.learning = res
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		Getspecialist() {
			//获取专家风采
			let params = {
				columnId: '214'
			}
			//listInfoByColumnName(params)
			listInfoByColumnId(params)
				.then(res => {
					this.speciaLen = Math.ceil(res.length / 4)

					for (let i = 0; i < this.speciaLen; i++) {
						let num = i * 4
						this.specialist.push({ child: res.slice(num, num + 4) })
					}
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		Getnews(columnId) {
			//获取新闻
			let params = {
				pageSize: 4,
				pageNum: 1,
				columnId: columnId
			}
			//pageListInfoByColumnName(params)
			pageListInfoByColumnId(params)
				.then(res => {
					this.newsList = res.rows
					// if (res.length > 4) {
					// 	this.newsList = res.slice(0, 4)
					// } else {
					// 	this.newsList = res
					// }
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		Rotationchart() {
			//获取轮播图
			let params = {
				infoName: '轮播图'
			}

			listInfoByInfoName(params)
				.then(res => {
					if (res.length) {
						this.carouselList = res[0].noticeContent.split(',')
					}
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		newItem(item, i) {
			//点击新闻资讯列表进入详情
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == this.newstabName) {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/newdetaile',
				query: {
					id: item.noticeId
				}
			})
		},
		newsPage() {
			//新闻资讯了解更多按钮
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == this.newstabName) {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/news',
				query: {
					tabName: this.newstabName
				}
			})
		},
		navClick(item, i) {
			//切换新闻tab
			this.newstabName = item.name
			this.navLen = i
			this.Getnews(item.columnId)
		},

		speClick(item, k) {
			this.spelen = k
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == item.sysColumn.columnName) {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/newdetaile',
				query: {
					id: item.noticeId
				}
			})
		},
		about() {
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == '关于学会') {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/learn',
				query: {
					type: '2'
				}
			})
		}
	}
}
</script>
<style scoped lang="scss">
.homePage {
	width: 100%;
	.banner {
		width: 100%;
		height: 500px;
		.carousel-img {
			display: block;
			margin: 0 auto;
			width: 1920px;
			height: 100%;
		}
	}
	.realtimeinfo {
		width: 100%;
		background: #fff;
		.realtimeinfo-tit {
			width: 1330px;
			margin: 0 auto;
			.top-nav {
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding-bottom: 34px;
				padding: 123px 0px 47px 0px;

				.left-nav {
					display: flex;
					flex-direction: row;
					height: 34px;
					.nav-item {
						border-right: 1px solid #f1f1f1;
						display: flex;
						flex-direction: row;
						padding: 0px 16px;
						cursor: pointer;
						div:nth-child(1) {
							width: 29px;
							height: 28px;
							margin-right: 10px;
							font-size: 32px;
							line-height: 32px;
						}
						div:nth-child(2) {
							font-size: 28px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
							line-height: 28px;
						}
					}
					.nav-item:last-child {
						border: none;
					}
					.nav-cative {
						color: #288183 !important;
					}
				}
				.ri-btn {
					width: 162px;
					height: 46px;
					background: #288183;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #fefefe;
					line-height: 36px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
				}
			}
			.realtimeinfo-body {
				width: 100%;
				margin: 0 auto;
				margin-bottom: 123px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #f1f1f1;
				flex-wrap: wrap;
				.left-bdoy-item {
					border-top: 1px solid #f1f1f1;
					padding: 28px 0px;
					width: 49%;
					display: flex;
					justify-content: space-between;
					cursor: pointer;
					.body-img {
						width: 240px;
						height: 180px;
						overflow: hidden;
						img {
							width: auto;
							// height: 180px;
						}
					}
					.body-text {
						flex: 1;
						padding: 0px 30px;
						p:nth-child(1) {
							font-size: 18px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #288183;
							line-height: 28px;
							text-align: left;
						}
						p:nth-child(2) {
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #333333;
							line-height: 32px;
							text-align: left;
							padding-top: 12px;
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
						p:nth-child(3) {
							text-align: left;
							display: block;
							margin-top: 22px;
							font-size: 22px;
							color: #288183;
						}
					}
				}
			}
		}
	}
	.specialist {
		width: 100%;
		background: #fff;
		padding-bottom: 103px;
		.specialist-box {
			width: 1330px;
			margin: 0 auto;

			.specialist-tit {
				display: flex;
				justify-content: space-between;
				padding: 79px 0px 49px;
				.spe-tit-left {
					padding-top: 14px;
					display: flex;
					flex-direction: row;
					p:nth-child(1) {
						width: 26px;
						height: 26px;
						background: url(../../static/images/specia.png) no-repeat center;
						background-size: 100% 100%;
						margin-top: 2px;
					}
					p:nth-child(2) {
						font-size: 28px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #288183;
						line-height: 28px;
						padding-left: 10px;
					}
				}
				.spe-tit-ri {
					display: flex;
					flex-direction: row;

					div {
						width: 61px;
						height: 61px;
						background: #288183;
						border-radius: 50%;
						text-align: center;
						line-height: 61px;
						color: #fff;
						font-size: 14px;
						margin-left: 20px;
						cursor: pointer;
					}
				}
			}
			.spe-carouse {
				height: 464px;
				.carouse-list {
					// display: flex;
					// flex-direction: row;
					width: 100%;
					.carouse-item {
						width: 317px;
						height: 460px;
						// flex: 4;
						float: left;
						padding: 0px 10px;
						display: flex;
						flex-direction: column;
						overflow: hidden;
						.carouse-list-img {
							width: 317px;
							height: 370px;
							overflow: hidden;
						}

						.carouse-text {
							padding: 18px 0px;
							p {
								text-align: left;
								i {
									float: right;
									padding-right: 20px;
								}
							}
							p:nth-child(1) {
								font-size: 22px;
								font-family: Source Han Sans CN;
								font-weight: 500;
								color: #333333;
								line-height: 32px;
							}
							p:nth-child(2) {
								font-size: 16px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #666666;
								line-height: 22px;
								display: flex;
								justify-content: space-between;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}

						.spe-active {
							background: #288183;
							padding-left: 20px;
							p {
								color: #fff !important;
							}
						}
					}
					.carouse-item:nth-child(1) {
						padding-left: 0px;
					}
					.carouse-item:last-child {
						padding-right: 0px;
					}
				}
			}
		}
	}
	.learning-box {
		width: 100%;
		background: #fff;
		.learning {
			width: 1330px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			padding: 102px 0px 31px;
			.learning-item {
				flex: 2;
				display: flex;
				flex-direction: column;
				.learning-carousel-tit {
					display: flex;
					flex-direction: row;
					padding-top: 12px;
					p:nth-child(1) {
						width: 27px;
						height: 27px;
						background: url(../../static/images/xues.png) no-repeat center;
						background-size: 100% 100%;
					}
					p:nth-child(2) {
						font-size: 28px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #288183;
						line-height: 28px;
						padding-left: 10px;
					}
				}
				.learning-carousel {
					padding: 48px 50px 0px 0px;
					position: relative;
					.learning-carousel-item {
						display: flex;
						flex-direction: column;
						cursor: pointer;
						height: 505px;
						padding-bottom: 50px;
						background-size: 100% 100%;
						.carousel-item-img {
							position: absolute;
							left: 0px;
							top: 0px;
							width: 100%;
							height: 505px;
							display: block;
							z-index: -1;
						}
						p:nth-child(2) {
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #ffffff;
							line-height: 30px;
							text-align: left;
							padding: 64px 30px 220px;
						}
						p:nth-child(3) {
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #ffffff;
							line-height: 30px;
							text-align: left;
							padding: 0px 30px 34px;
						}
						p:nth-child(4) {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #ffffff;
							line-height: 26px;
							text-align: left;
							padding: 0px 30px 34px;
						}
					}
					.indicator-position-icon {
						display: flex;
						justify-content: space-between;
						position: absolute;
						right: 66px;
						bottom: 0px;
						i {
							display: block;
							width: 61px;
							height: 61px;
							background: #288183;
							text-align: center;
							line-height: 61px;
							color: #fff;
							border-radius: 50%;
							z-index: 100;
							margin-right: 10px;
							cursor: pointer;
						}
					}
				}

				.learning-text-tit {
					padding-left: 50px;
					display: flex;
					flex-direction: row;
					.learning-text-tit-item {
						display: flex;
						flex-direction: row;
						padding-right: 20px;
						border-right: 1px solid #999999;
						margin-right: 20px;
						cursor: pointer;

						p {
							font-size: 28px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
							line-height: 46px;
							padding-right: 10px;
						}
						p:nth-child(1) {
							font-size: 32px;
							line-height: 46px;
						}
					}
					.education-active p {
						color: #288183 !important;
					}
					.learning-text-tit-item:last-child {
						border: none;
					}
					.lear-more {
						width: 162px;
						height: 46px;
						background: #288183;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #fefefe;
						line-height: 36px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						margin-left: 40px;
					}
				}
				.learning-text-list {
					padding: 38px 0px 0px 50px;
					display: flex;
					flex-direction: column;
					.list-item {
						padding: 44px 0px;
						display: flex;
						flex-direction: row;
						border-top: 1px solid #e5e5e5;
						div:nth-child(1) {
							padding-right: 20px;
							p:nth-child(1) {
								font-size: 18px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #333;
								line-height: 28px;
								text-align: left;
							}
							p:nth-child(2) {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #a0a0a0;
								line-height: 28px;
								text-align: left;
							}
						}
						div:nth-child(2) {
							width: 77px;
							height: 50px;
							border-left: 1px solid #ccc;
							font-size: 24px;
							line-height: 50px;
							margin-top: 20px;
						}
					}
					.list-item:hover div p {
						cursor: pointer;
						color: #288183 !important;
					}
					.list-item:hover div {
						cursor: pointer;
						color: #288183 !important;
					}
				}
			}
		}
	}
	.about-box {
		width: 100%;
		padding: 86px 0px 140px;
		.about {
			width: 1330px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			.about-item {
				p {
					text-align: left;
				}
				p:nth-child(1) {
					font-size: 48px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					line-height: 64px;
				}
				p:nth-child(2) {
					font-size: 36px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					line-height: 64px;
				}
				.about-html {
					margin-top: 50px;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #747474;
					line-height: 32px;
					height: 295px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 9;
					-webkit-box-orient: vertical;
				}

				.about-item-btn {
					width: 161px;
					height: 46px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #fefefe;
					line-height: 16px;
					background: #288183;
					margin-top: 36px;
					cursor: pointer;
					span:nth-child(2) {
						padding-left: 10px;
					}
				}
				img {
					display: block;
					width: 100%;
					height: 560px;
				}
			}
			.about-item:nth-child(1) {
				width: 496px;
				padding-right: 120px;
			}
			.about-item:nth-child(2) {
				flex: 1;
			}
		}
	}
}
</style>
